import request from '@/utils/request'

// 查询客户激活详情
export function findAgentActiveOrder(id) {
  return request({
    url: `/agent_active_orders/${id}`,
    method: 'get'
  })
}

// 查询客户激活详情卡号列表
export function findSimCardActiveOrders(id, params) {
  return request({
    url: `/agent_active_orders/${id}/sim_card_active_orders`,
    method: 'get',
    params: params
  })
}

// 导出激活详情卡号列表
export function exportSimCardActiveOrders(id, data) {
  return request({
    url: `/agent_active_orders/${id}/sim_card_active_orders/export`,
    method: 'post',
    data
  })
}

